     export default{
    _TOKEN_KEY: 'token',
    _LANG_KEY: 'lang',
    _DEFAULT_LANG: 'en',
    _API_URL: process.env.NODE_ENV === 'production'
            ? 'https://rwa.shelldao.cash/api/'
            // : 'https://bdapi.bci.cash/api/',
            : 'https://rwa.shelldao.cash/api/',
    _BASE_URL: process.env.NODE_ENV === 'production'
            ? 'https://rwa-h5.shelldao.cash/#/'
            : 'http://localhost:8080/#/',
}
